import React, { useEffect, useRef } from 'react';
import './App.css'; // Make sure your CSS is linked here

function App() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvasElement = canvasRef.current;
    canvasElement.width = window.innerWidth;
    canvasElement.height = window.innerHeight;
    const ctx = canvasElement.getContext('2d');

    let particles = [];
    let microparticles = [];

    class Particle {
      constructor() {
        this.x = window.innerWidth / 2 + (Math.random() * 20 - Math.random() * 20);
        this.y = window.innerHeight;
        this.s = 2 + Math.random();
        this.radius = 1 + 3 * Math.random();
        this.color = "#D4AF37";
        this.direction = Math.random() > 0.5 ? -1 : 1;
        this.life = 100 + Math.random() * 3000;
        this.ID = setInterval(() => {
          microparticles.push(new MicroParticle(this.x, this.y));
        }, 100);
      }

      move() {
        this.x -= this.direction * Math.sin(this.life / 100) * this.s;
        this.y -= Math.cos(this.life / 100) * this.s;
        this.life--;
        if (this.life < 0) {
          clearInterval(this.ID);
          return false;
        }
        return true;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
        ctx.fillStyle = this.color;
        ctx.fill();
        ctx.closePath();
      }
    }

    class MicroParticle {
      constructor(x, y) {
        this.x = x;
        this.y = y;
        this.s = 2 + Math.random();
        this.radius = 1 + Math.random() * 0.5;
        this.color = "#D4AF37";
      }

      move() {
        this.x += Math.sin(100 / this.s);
        this.y -= Math.cos(100 / this.s);
        if (this.y < 0) {
          return false;
        }
        return true;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
        ctx.fillStyle = this.color;
        ctx.fill();
        ctx.closePath();
      }
    }

    function clear() {
      ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    }

    function update() {
      clear();
      particles = particles.filter(particle => particle.move());
      microparticles = microparticles.filter(microParticle => microParticle.move());
      particles.forEach(particle => particle.draw());
      microparticles.forEach(microParticle => microParticle.draw());
      requestAnimationFrame(update);
    }

    // Initiate the particle generation
    particles.push(new Particle());
    update();

    // Cleanup function
    return () => {
      particles.forEach(particle => clearInterval(particle.ID));
    };
  }, []);

  return (
    <div className="App" style={{ position: 'relative', height: '100vh' }}>
      <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, height: '100%' }}></canvas>
      <div className="title" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
        <h1>FREQUENCY13</h1>
        <h3>COMING</h3>
        <h2>SUMMER <strong>2024</strong></h2>
      </div>
    </div>
  );
}

export default App;